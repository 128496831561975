import { format } from 'date-fns';
import { get, HttpResponse, getForClient } from './request';

export interface NewsCenterItem {
  newsNo: string;
  newsImg: string;
  newsFocusImg?: string;
  newsTitle: string;
  newsAbstract: string;
  newsDate: string;
  newsType: string;
  newsTypeId: string;
  newsOrigin?: string;
  newsCardType?: string;
}

/**
 * 新闻中心-新闻分页查询
 */
interface NewsPageParams {
  pageSize: number;
  pageNo: number;
  siteNo?: string;
  newsClassifyId?: string | number;
  title?: string;
  lanType: string;
  notNewsNo?: string;
}

interface NewsPageItem {
  siteNo: string;
  newsNo: string;
  lanType: string;
  title: string;
  content: string;
  newsAbstract: string;
  thumbnailPic: string;
  thumbnailType: string;
  newsClassifyId: string;
  newsClassifyName: string;
  newsTime: number;
  copyrightAddress: string;
  copyrightName: string;
}

interface NewsPageList {
  page: {
    pageSize: number;
    pageNo: number;
    totalCount: number;
    totalPageCount: number;
  };
  pageContent: NewsPageItem[];
}

/**
 * 服务端请求接口
 * @param params
 * @returns
 */
export const getNewsPage = (params: NewsPageParams) => {
  return get<NewsPageParams, HttpResponse<NewsPageList>>('/smart/view/news/r/tNewsService_pageGetByQuery', params).then(
    ({ data, code }) => {
      if (code !== 0) throw new Error('获取新闻列表失败');

      const result = data?.pageContent?.map((item) => {
        return {
          newsImg: item.thumbnailPic,
          newsTitle: item.title,
          newsNo: item.newsNo,
          newsAbstract: item.newsAbstract,
          newsDate: format(new Date(item.newsTime), 'yyyy-MM-dd'),
          newsType: item.newsClassifyName,
          newsTypeId: item.newsClassifyId,
          newsOrigin: item.copyrightName,
          newsCardType: item.thumbnailType,
        };
      });
      return {
        pageContent: result,
        total: data.page.totalCount,
      };
    },
  );
};
/**
 *
 */
export const clientGetNewsPage = (params: NewsPageParams) => {
  return getForClient<NewsPageParams, HttpResponse<NewsPageList>>(
    '/smart/view/news/r/tNewsService_pageGetByQuery',
    params,
  ).then(({ data, code }) => {
    if (code !== 0)
      return {
        pageContent: [],
        total: 0,
      };
    const result = data?.pageContent?.map((item) => {
      return {
        newsImg: item.thumbnailPic,
        newsTitle: item.title,
        newsNo: item.newsNo,
        newsAbstract: item.newsAbstract,
        newsDate: format(new Date(item.newsTime), 'yyyy-MM-dd'),
        newsType: item.newsClassifyName,
        newsTypeId: item.newsClassifyId,
        newsOrigin: item.copyrightName,
        newsCardType: item.thumbnailType,
      };
    });
    return {
      pageContent: result,
      total: data.page.totalCount,
    };
  });
};
/**
 * 新闻中心，查看详情
 */

interface NewsDetailParams {
  newsNo: string;
  lanType: string;
}

interface NewsDetail {
  siteNo: string;
  newsNo: string;
  lanType: string;
  title: string;
  content: string;
  newsAbstract: string;
  thumbnailPic: string;
  thumbnailType: string;
  sharePic: string;
  newsClassifyId: string;
  newsClassifyName: string;
  newsTime: string;
  copyrightAddress: string;
  copyrightName: string;
}

export const getNewsDetail = (url: string, params: NewsDetailParams) => {
  return get<NewsDetailParams, HttpResponse<NewsDetail>>(url, params).then(async ({ data, code }) => {
    if (code !== 0) throw new Error('获取新闻详情失败');
    let contentJson: any = null;
    try {
      contentJson = await get(data?.content);
    } catch (error) {
      contentJson = { html: '' };
    }
    data.content = contentJson?.html;

    // console.log(data);
    const result = {
      newsTitle: data.title,
      newsType: data.newsClassifyName,
      newsTypeId: data.newsClassifyId,
      newsDate: format(new Date(data.newsTime), 'yyyy-MM-dd'),
      newsOrigin: data.copyrightName,
      newsContent: data.content,
      newsAbstract: data.newsAbstract,
      thumbnailPic: data.thumbnailPic,
      sharePic: data.sharePic,
    };
    return result;
  });
};
export const getNewsFormalDetail = (params: NewsDetailParams) =>
  getNewsDetail('/smart/view/news/r/tNewsService_getDetailByNewsNo', params);

/* 获取新闻预览详情 */
export const getNewsPreviewDetail = (params: NewsDetailParams) =>
  getNewsDetail('/smart/view/news/r/tNewsService_getPreviewDetailByNewsNo', params);

/**
 * 推荐新闻列表
 */

interface PositionNewsParams {
  positionNo: string;
  lanType: string;
  limit?: number;
}

interface PositionNewsItem {
  siteNo: string;
  newsNo: string;
  lanType: string;
  title: string;
  newsAbstract: string;
  /* 普通推荐位缩略图 */
  thumbnailPic: string;
  thumbnailType: string;
  /* 焦点推荐位缩略图 */
  focusPic: string;
  newsClassifyId: string;
  newsClassifyName: string;
  newsTime: number;
  copyrightAddress: string;
  copyrightName: string;
}

export const getPositionNewsList = (params: PositionNewsParams) => {
  const { limit = 3 } = params;
  delete params.limit;
  return getForClient<PositionNewsParams, HttpResponse<PositionNewsItem[]>>(
    '/smart/view/news/r/tNewsPositionService_listNews',
    params,
  ).then(({ data, code }) => {
    if (code !== 0) return [];
    const result = data?.map((item) => {
      return {
        newsImg: item.thumbnailPic,
        newsTitle: item.title,
        newsNo: item.newsNo,
        newsAbstract: item.newsAbstract,
        newsDate: format(new Date(item.newsTime), 'yyyy-MM-dd'),
        newsType: item.newsClassifyName,
        newsTypeId: item.newsClassifyId,
        newsOrigin: item.copyrightName,
        newsCardType: item.thumbnailType,
      };
    });
    return result?.slice(0, limit);
  });
};

/**
 * 批量获取推荐新闻列表
 */

interface PositionListNewsParams {
  positionNoList: string[];
  lanType: string;
}

interface PositionListNewsList {
  [key: string]: PositionNewsItem[];
}

type NewsCenterObj = Record<string, NewsCenterItem[]>;

export const getPositionListNewsList = (params: PositionListNewsParams) => {
  /* 这个接口后端固定了缓存key：返回结果不会随positionNoList变更而变更，仅此处（首页）可以调用 */
  return get<null, HttpResponse<PositionListNewsList>>(
    `/smart/view/news/r/tNewsPositionService_listNewsByPositionList?lanType=${
      params.lanType
    }&positionNoList=${JSON.stringify(params.positionNoList)}`,
    null,
  ).then(({ code, data }) => {
    params.positionNoList = [...params.positionNoList];
    if (code !== 0) throw new Error('获取推荐新闻出错');
    const result = Object.entries(data)?.reduce((pre: NewsCenterObj, item) => {
      pre[item[0]] = item[1]?.map((valueItem) => {
        return {
          newsFocusImg: valueItem.focusPic,
          newsImg: valueItem.thumbnailPic,
          newsTitle: valueItem.title,
          newsNo: valueItem.newsNo,
          newsAbstract: valueItem.newsAbstract,
          newsDate: format(new Date(valueItem.newsTime), 'yyyy-MM-dd'),
          newsType: valueItem.newsClassifyName,
          newsTypeId: valueItem.newsClassifyId,
          newsOrigin: valueItem.copyrightName,
          newsCardType: valueItem.thumbnailType,
        };
      });
      return pre;
    }, {});
    return result;
  });
};

/**
 * 新闻分类查询
 */

interface NewsCategoryItem {
  siteNo: string;
  classifyId: number | null;
  zhCnName: string;
  enUsName: string;
  zhHkName: string;
  weight: number;
}

enum lanTypeEnum {
  'zh-CN' = 'zhCnName',
  'zh-HK' = 'zhHkName',
  'en-US' = 'enUsName',
}

enum allWordMap {
  'zh-CN' = '全部',
  'zh-HK' = '全部',
  'en-US' = 'All',
}

interface NewsCategoryParams {
  lanType: keyof typeof lanTypeEnum;
}
/**
 * 服务端的渲染接口
 * @param params
 * @returns
 */
export const getNewsCategory = (params: NewsCategoryParams) => {
  return get<null, HttpResponse<NewsCategoryItem[]>>('/smart/view/news/r/tNewsClassifyService_listAll').then(
    ({ data, code }) => {
      if (code !== 0) throw new Error('获取新闻分类出错');
      const result = data?.map((item) => {
        return {
          typeName: item[lanTypeEnum[params.lanType]],
          typeVal: item.classifyId,
        };
      });
      result.unshift({ typeName: allWordMap[params.lanType], typeVal: null });
      return result;
    },
  );
};

export const clientGetNewsCategory = () => {};

import axios from 'axios';
import { Env, getEnv } from '../util/env';
import { logger } from '../util/logger';
import { catTransaction } from '../util/Cat';

export interface HttpResponse<T> {
  code: number;
  message: string;
  data: T;
}

const baseURLMap = {
  [Env.development]: 'http://capapi.fsp.test.sankuai.com/',
  [Env.test]: 'http://capapi.fsp.test.sankuai.com/',
  [Env.staging]: 'http://capapi.fsp.st.sankuai.com/', // 'https://about.cap.st.sankuai.com/',
  [Env.production]: 'http://capapi.vip.sankuai.com/',
  [Env.local]: 'http://capapi.fsp.test.sankuai.com/',
};

const ENV = getEnv();
// const ENV = 'test' as keyof typeof baseURLMap;

// 这个域名不需要写死，因为是在服务端请求所以需要写死host
// 页面中还有在客户端请求的方案，建议此处不需要写死host
// axios.defaults.baseURL = baseURLMap[ENV] || baseURLMap.test;

// logger.info(`ENV, ${ENV} ${baseURLMap[ENV] || baseURLMap.test}`);

export async function get<T, P>(url: string, params?: T): Promise<P> {
  const subscrible = catTransaction('getServerSideProps', url);
  try {
    const { data } = await axios.get<P>(url, {
      params,
      headers: {
        'Accept-Encoding': 'deflate',
      },
      baseURL: baseURLMap[ENV] || baseURLMap.test,
    });
    subscrible();
    return data;
  } catch (error) {
    logger.error(error);
    subscrible(false); // 上报错误
    throw new Error(`请求失败:${url}`);
  }
}
export async function post<T, P>(url: string, params?: T): Promise<P> {
  const subscrible = catTransaction('getServerSideProps', url);
  try {
    const { data } = await axios.post<P>(url, params, {
      baseURL: baseURLMap[ENV] || baseURLMap.test,
    });
    subscrible();
    return data;
  } catch (error) {
    logger.error(error);
    subscrible(false); // 上报错误
    throw new Error(`请求失败:${url}`);
  }
}

/**
 * 给浏览器请求的 get 接口
 * @param url
 * @param params
 * @returns
 */
export function getForClient<T, P>(url: string, params?: T): Promise<P> {
  return axios
    .get<P>(url, {
      params,
    })
    .then((res) => {
      return res.data;
    });
}

/**
 * 给浏览器请求的 post 接口
 * @param url
 * @param params
 * @returns
 */
export function postForClient<T, P>(url: string, params?: T): Promise<P> {
  return axios.post<P>(url, params).then(({ data }) => data);
}
